import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: #FCF8EE;

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
    color: ${orange.dark}
  }

  a {
    @media ${device.tablet} {
      width: 306x;
    }

    @media ${device.desktopLG} {
      width: 390x;
    }
  }
`
